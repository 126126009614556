<template>
  <div class="container">
    <div class="restaurant">
      <v-row class="">
        <v-col cols="12" class="top-panel">
          <div class="arrow-btn">
            <router-link
              v-if="
                user &&
                user.roles &&
                user.roles.length > 0 &&
                user.roles[0].employeeRole === 'Root'
              "
              to="/ProductList1"
            >
              <img
                src="../assets/img/arrowBack.svg"
                class="img-pluss-btn"
                alt="arrow-back"
              />
            </router-link>
            <router-link v-else to="/ProductList1">
              <img
                src="../assets/img/arrowBack.svg"
                class="img-pluss-btn"
                alt="arrow-back"
              />
            </router-link>
          </div>
          <div class="vertical-line-1"></div>
        </v-col>
      </v-row>
      <v-row class="product-big-container">
        <v-col cols="12" class="title-container py-0">
          <h1 class="page-title">Новый продукт</h1>
        </v-col>
      </v-row>
      <v-row class="product-big-container">
        <v-col cols="12" class="title-container-tabs">
          <template>
            <div>
              <v-row>
                <v-col cols="4">
                  <div class="content-container">
                    <v-col cols="12">
                      <h2 class="information-title pb-0">Описание</h2>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Название</p>
                        </v-col>
                        <v-col cols="" class="col-paddings-r-l px-0 py-0">
                          <textarea
                            class="col-description textarea-pt"
                            v-model="nameRu"
                            ref="nameRuTextarea"
                            :placeholder="
                              isnameRUFocused ? '' : textareaPlaceholder
                            "
                            @focus="isnameRUFocused = true"
                            @blur="isnameRUFocused = false"
                          ></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>

                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Название EN</p>
                        </v-col>
                        <v-col cols="5" class="col-paddings-r-l px-0 py-0">
                          <textarea
                            class="col-description textarea-pt"
                            v-model="nameEN"
                            ref="nameENTextarea"
                            :placeholder="
                              isnameENFocused ? '' : textareaPlaceholder
                            "
                            @focus="isnameENFocused = true"
                            @blur="isnameENFocused = false"
                          ></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>

                    <v-col cols="12" class="py-0">
                      <div class="col-content" style="height: 63px">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Вес</p>
                        </v-col>

                        <div
                          class="col-paddings-r-l input-select-width px-0 py-0"
                        >
                          <input
                            v-model="weight"
                            placeholder="---"
                            class="weight-text"
                            type="text"
                            oninput="this.value = this.value.replace(/[^0-9.,]/g, '')"
                          />
                        </div>
                        <div
                          class="col-paddings-r-l px-0 py-0 select-container"
                        >
                          <v-select
                            class=""
                            v-model="selectedOption"
                            :items="options"
                            item-value="value"
                            item-text="text"
                            variant="underlined"
                          >
                          </v-select>
                        </div>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>

                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Производитель</p>
                        </v-col>

                        <v-col cols="5" class="col-paddings-r-l px-0 py-0">
                          <textarea
                            class="col-description textarea-pt"
                            v-model="manufacture"
                            ref="manufactureTextarea"
                            :placeholder="
                              ismanufactureFocused ? '' : textareaPlaceholder
                            "
                            @focus="ismanufactureFocused = true"
                            @blur="ismanufactureFocused = false"
                          ></textarea>
                        </v-col>
                      </div>
                    </v-col>

                    <div class="horizontal-line"></div>

                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Страна</p>
                        </v-col>

                        <v-col cols="5" class="col-paddings-r-l px-0 py-0">
                          <textarea
                            class="col-description textarea-pt"
                            v-model="originRU"
                            ref="originRUTextarea"
                            :placeholder="
                              isoriginRUFocused ? '' : textareaPlaceholder
                            "
                            @focus="isoriginRUFocused = true"
                            @blur="isoriginRUFocused = false"
                          ></textarea>
                        </v-col>
                      </div>
                    </v-col>

                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Страна EN</p>
                        </v-col>

                        <v-col cols="5" class="col-paddings-r-l px-0 py-0">
                          <textarea
                            class="col-description textarea-pt"
                            v-model="originEN"
                            ref="originENTextarea"
                            :placeholder="
                              isoriginENFocused ? '' : textareaPlaceholder
                            "
                            @focus="isoriginENFocused = true"
                            @blur="isoriginENFocused = false"
                          ></textarea>
                        </v-col>
                      </div>
                    </v-col>

                    <div class="horizontal-line"></div>

                    <v-col cols="12" class="py-0">
                      <div class="col-content" style="height: 63px">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Упаковка</p>
                        </v-col>

                        <v-col cols="" class="col-paddings-r-l px-0 py-0">
                          <v-select
                            v-model="selectedOptionPackageType"
                            :items="PackageType"
                            class=""
                            variant="underlined"
                          ></v-select>
                        </v-col>
                      </div>
                    </v-col>

                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Описание</p>
                        </v-col>

                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <textarea
                            class="col-description textarea-pt"
                            v-model="descriptionRU"
                            ref="descriptionRUTextarea"
                            :placeholder="
                              isdescriptionRUFocused ? '' : textareaPlaceholder
                            "
                            @focus="isdescriptionRUFocused = true"
                            @blur="isdescriptionRUFocused = false"
                          ></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>

                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Описание EN</p>
                        </v-col>

                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <textarea
                            class="col-description textarea-pt"
                            v-model="descriptionEN"
                            ref="descriptionENTextarea"
                            :placeholder="
                              isdescriptionENFocused ? '' : textareaPlaceholder
                            "
                            @focus="isdescriptionENFocused = true"
                            @blur="isdescriptionENFocused = false"
                          ></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0" style="height: 63px">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Срок годности</p>
                        </v-col>

                        <v-col cols="1" class="col-paddings-r-l px-0 py-0">
                          <input
                            v-model="bestBeforeMonths"
                            placeholder="---"
                            class="weight-text"
                            maxlength="3"
                            type="text"
                            oninput="this.value = this.value.replace(/[^0-9.,]/g, '')"
                          />
                        </v-col>
                        <p v-if="bestBeforeMonths" class="month-text">
                          {{ pluralizeMonths(bestBeforeMonths) }}
                        </p>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="">
                      <div class="col-content-energy px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">
                            Энергетическая ценность <br />
                            в 100 г продукта:
                          </p>
                        </v-col>

                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <textarea
                            class="col-description"
                            v-model="energyValueRU"
                            ref="energyValueRUTextarea"
                            :placeholder="
                              isenergyValueRUFocused ? '' : textareaPlaceholder
                            "
                            @focus="isenergyValueRUFocused = true"
                            @blur="isenergyValueRUFocused = false"
                          ></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="">
                      <div class="col-content-energy px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">
                            Энергетическая ценность <br />в 100 г продукта EN:
                          </p>
                        </v-col>

                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <textarea
                            class="col-description"
                            v-model="energyValueEN"
                            ref="energyValueENTextarea"
                            :placeholder="
                              isenergyValueENFocused ? '' : textareaPlaceholder
                            "
                            @focus="isenergyValueENFocused = true"
                            @blur="isenergyValueENFocused = false"
                          ></textarea>
                        </v-col>
                      </div>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="content-container mb-5">
                    <v-col cols="12">
                      <h2 class="information-title pb-0">
                        Дополнительное описание
                      </h2>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Предупреждение</p>
                        </v-col>
                        <v-col cols="" class="col-paddings-r-l px-0 py-0">
                          <textarea
                            class="col-description textarea-pt"
                            v-model="additionalComponentRU"
                            ref="additionalComponentRUTextarea"
                            :placeholder="
                              isadditionalComponentRUFocused
                                ? ''
                                : textareaPlaceholder
                            "
                            @focus="isadditionalComponentRUFocused = true"
                            @blur="isadditionalComponentRUFocused = false"
                          ></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Предупреждение EN</p>
                        </v-col>
                        <v-col cols="" class="col-paddings-r-l px-0 py-0">
                          <textarea
                            class="col-description textarea-pt"
                            v-model="additionalComponentEN"
                            ref="additionalComponentENTextarea"
                            :placeholder="
                              isadditionalComponentENFocused
                                ? ''
                                : textareaPlaceholder
                            "
                            @focus="isadditionalComponentENFocused = true"
                            @blur="isadditionalComponentENFocused = false"
                          ></textarea>
                        </v-col>
                      </div>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="btn-save-container">
                    <button class="btn-save" @click="handleButtonClick">
                      Сохранить
                    </button>
                  </div>
                </v-col>
              </v-row>
              <v-snackbar
                v-model="snackbar"
                :timeout="3000"
                class="btn-contaiener"
              >
                {{ message }}
                <template v-slot:action="{ attrs }">
                  <div
                    class="snackbar-btn"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Закрыть
                  </div>
                </template>
              </v-snackbar>
            </div>
          </template>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style scoped>
.page-title {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.snackbar-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  margin-right: auto;
  padding: 14px 16px;
  text-align: initial;
  cursor: pointer;
}
.btn-contaiener {
  display: flex;
  justify-content: space-evenly;
}
.btn-save-container {
  text-align: end;
}
.btn-save {
  border-radius: 8px;
  background: var(--03, #bcb648);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  color: var(--06, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.btn-photo {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.photo-description {
  display: flex;
  align-items: center;
}
.information-text-3 {
  color: #9e9e9e;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 30px;
}
.information-text-2 {
  color: #9e9e9e;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 43px;
}
.information-text-1 {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}
.input-select-width {
  width: 30%;
}
.product-big-container {
  margin-left: 39px;
  margin-right: 30px;
}
.col-content-energy {
  display: flex;
  align-items: flex-start;
}
.weight-text {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-bottom: 0px;
}
.month-text {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-bottom: 0px;
}
::v-deep
  .theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ):hover {
  background: #f2f2f680;
}

::v-deep .v-list-item__title {
  align-self: center;

  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}
::v-deep .v-application .primary--text {
  color: #bcb648 !important;
  caret-color: #bcb648 !important;
}
::v-deep .v-select__selection--comma {
  margin: 7px 4px 7px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}
::v-deep .v-select__slot {
  position: relative;
  align-items: center;
  display: flex;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  border-radius: 3px;
  border: 1px solid var(--03, #c1c1c1);
  background: var(--06, #fff);
}
::v-deep .v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  bottom: -1px;
  content: none !important;
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.select-text {
  padding-left: 5px;
}
.select-container {
  margin: 0 0 0 auto;
  width: 63px;
}
.textarea-pt {
  padding-top: 19px;
}
.work-margin-bot {
  margin-bottom: 50px;
}
.col-title-work {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.special-border:focus {
  border-radius: 3px;
  border: 2px solid var(--03, black);
  background: var(--white, #fff);
  width: 295px;
  height: 37px;
  padding-left: 10px;
}
.special-border {
  border-radius: 3px;
  border: 1px solid var(--03, #c1c1c1);
  background: var(--white, #fff);
  width: 295px;
  height: 37px;
  padding-left: 10px;
}
.time-end {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
}

.time-start {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
}
.end-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.start-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
}

.work-img-margin {
  margin-right: 21px;
}
.work-1 {
  text-align: end;
}
.dot-work {
  margin-left: 69px;
}
.work-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title-container {
  margin-top: 17px;
}

.img-pluss-btn {
  margin-left: 10px;
}
.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
/* .top-panel .container {
    width: 100%;
    padding: 0px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  } */
.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}
.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}
.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}
.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black; /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}
.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  margin-left: 37px;
  cursor: pointer;
  text-decoration: none;
}

.top-panel {
  border-bottom: 1px solid var(--04, #eaeaea);
  background: var(--white, #fff);
  height: 50px;
  display: flex;
  align-items: center;
}

.full-text-input-3 {
  white-space: normal; /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}
.full-text-input-2 {
  white-space: normal; /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input {
  white-space: normal; /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}
.full-text-input,
.full-text-input-2,
.full-text-input-3,
::placeholder {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.full-text-input,
:focus {
  outline: none; /* Убираем внешний контур (outline) при фокусе */
  border: none; /* Убираем границу (border) при фокусе */
  box-shadow: none; /* Убираем тень (box-shadow) при фокусе */
}
.requisites-text-container {
  display: flex !important;
  flex-direction: row;
  /* justify-content: space-evenly; */
  align-items: baseline;
}
.requisites-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 227px;
}
.margin-logo-divider {
  margin-bottom: 48px;
}
.logo-dot-3 {
  margin-right: 13px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.logo-dot-2 {
  margin-right: 16px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.logo-dot {
  margin-right: 69px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.custom-file-upload {
  cursor: pointer;
}
.img-container-flex {
  display: flex;
  justify-content: space-between;
}
.uploaded-image-logo {
  /* max-width: 392px; 
  max-height: 382px;  */
  max-width: 100%; /* Adjust this value as needed */
  max-height: 100%; /* Adjust this value as needed */
}
.uploaded-image-container {
  width: auto;
  height: auto;
  border-radius: 10px;
  border: 1px solid var(--05, #dae3ea);
  background: var(--06, #fff);
  padding: 14px;
}

.work-margin-img {
  margin-bottom: 15px;
}
.work-img {
  width: 22px;
  height: 22px;
}
.col-img {
  flex-direction: column-reverse;
  display: flex;
}
.work-margin {
  padding-bottom: 15px;
}
.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px; /* Толщина линии */
  background: #848484; /* Цвет линии (в данном случае черный) */
  margin-right: 12px;
  margin-left: 12px;
}
.tel-pointer {
  cursor: pointer;
}
.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
  /* height: 200px; */
  display: flex;
  align-items: center;
}

.col-paddings-t-b {
  padding-top: 0px;
  padding-bottom: 0px;
}
.col-content {
  display: flex;
  align-items: center;
}
.col-description {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  width: 100%;
  min-height: 20px; /* Минимальная высота для textarea */
}
.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  word-wrap: break-word;
}
.information-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.content-container {
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  background: var(--white, #fff);
  margin-top: 0px;
}
@media (min-width: 1264px) {
  .container {
    max-width: 100% !important;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 100% !important;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
export default {
  name: "CreateProduct",
  directives: { mask },
  props: {
    requestData: {},
    responseData: {},
  },
  data() {
    return {
      selectedImage: null,
      message: "",
      isPlaceholderHidden: false,
      isminOrderFocused: false,
      iscommisionFromOrderFocused: false,
      isorderRestinAppFocused: false,
      isphoneFocused: false,
      isinstagramFocused: false,
      isnameRUFocused: false,
      isnameENFocused: false,
      ismanufactureFocused: false,
      isoriginRUFocused: false,
      isoriginENFocused: false,
      isdescriptionRUFocused: false,
      isdescriptionENFocused: false,
      isbestBeforeMonthsFocused: false,
      isenergyValueRUFocused: false,
      isenergyValueENFocused: false,
      isadditionalComponentRUFocused: false,
      isadditionalComponentENFocused: false,
      minOrder: "",
      commisionFromOrder: "",
      nameRu: "",
      nameEN: "",
      manufacture: "",
      originRU: "",
      originEN: "",
      descriptionRU: "",
      descriptionEN: "",
      bestBeforeMonths: "",
      orderRestinApp: "",
      weight: "",
      uom: "",
      selectedOption: "",
      options: [
        {
          value: "Kilogramm",
          text: "КГ",
        },
        {
          value: "Gramm",
          text: "Г",
        },
      ],
      selectedOptionPackageType: "",
      PackageType: [
        {
          value: "PlasticBox",
          text: "Пластиковая коробка",
        },
        {
          value: "PlasticBag",
          text: "Пакет",
        },
        {
          value: "CardboardBox",
          text: "Подарочная коробка",
        },
        {
          value: "Plastic",
          text: "Пластик",
        },
        {
          value: "Can",
          text: "Банка",
        },
        {
          value: "WoodenBox",
          text: "Картонная Коробка",
        },
        {
          value: "Display",
          text: "Дисплей",
        },
      ],

      packageType: "",
      energyValueRU: "",
      energyValueEN: "",
      additionalComponentRU: "",
      additionalComponentEN: "",
      newProduct: {},
      snackbar: false,
    };
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
      console.log(`Вы выбрали опцию: ${option}`);
    },
    selectOptionPackage(PackageType) {
      this.selectedOptionPackageType = PackageType;
      console.log(`Вы выбрали опцию: ${PackageType}`);
    },
    createNewProduct() {
      //   this.newEmp.employeeRole = this.getEnglishRoleTranslation(
      //
      //   );
      (this.newProduct.name = this.nameRu),
        (this.newProduct.manufacturer = this.manufacture),
        (this.newProduct.origin = this.originRU),
        (this.newProduct.description = this.descriptionRU),
        (this.newProduct.bestBeforeMonths = this.bestBeforeMonths),
        (this.newProduct.weight = this.weight),
        (this.newProduct.uom = this.selectedOption),
        (this.newProduct.packageType = this.selectedOptionPackageType),
        (this.newProduct.energyValue = this.energyValueRU),
        (this.newProduct.additionalComponent = this.additionalComponentRU),
        // (this.newEmp.mobilePhone = this.phone),
        (this.newProduct.localizations = [
          {
            locale: "en",
            name: this.nameEN,
            description: this.descriptionEN,
            energyValue: this.energyValueEN,
            manufacturer: this.manufacture,
            origin: this.originEN,
            additionalComponent: this.additionalComponentEN,
          },
        ]),
        this.$http
          .post("/api/Product", this.newProduct)
          .then(() => {
            this.newProduct = {
              name: "",
              manufacture: "",
              origin: "",
              bestBeforeMonths: "",
              description: "",
              weight: "",
              uom: "",
              packageType: "",
              energyValue: "",
              additionalComponent: "",
              localizations: [
                {
                  locale: "",
                  name: "",
                  description: "",
                  energyValue: "",
                  manufacturer: "",
                  origin: "",
                  additionalComponent: "",
                },
              ],
              //   employeeRole: null,
            };

            this.snackbar = true;
            this.message = "Продукт успешно добавлен";
            setTimeout(() => {
              this.$router.push({ name: "ProductList1" });
            }, 2000); // 2000 миллисекунд (2 секунды)
          })
          .catch((error) => {
            this.error = error;
            this.snackbar = true;
            this.message = "Ошибка добавления продукта";
          });
    },

    handleButtonClick() {
      this.createNewProduct();
    },
    handleInput() {
      this.bestBeforeMonths = this.bestBeforeMonths.replace(/[^0-9.,]/g, "");
    },
    pluralizeMonths(months) {
      const num = parseInt(months);
      if (isNaN(num)) return "";

      if (num === 1) return "месяц";
      if (num > 1 && num < 5) return "месяца";
      return "месяцев";
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    hidePlaceholder(inputName) {
      if (inputName === "legalAdress") {
        this.isLegalAdressFocused = true;
      } else if (inputName === "actualAdress") {
        this.isActualAdressFocused = true;
      }
    },
    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      this.activeTextarea = null;
    },
  },

  watch: {
    nameRu() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("nameRuTextarea");
      });
    },
    nameEN() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("nameENTextarea");
      });
    },
    manufacture() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("manufactureTextarea");
      });
    },
    originRU() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("originRUTextarea");
      });
    },
    originEN() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("originENTextarea");
      });
    },
    descriptionRU() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("descriptionRUTextarea");
      });
    },
    descriptionEN() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("descriptionENTextarea");
      });
    },
    energyValueRU() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("energyValueRUTextarea");
      });
    },
    energyValueEN() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("energyValueENTextarea");
      });
    },
    additionalComponentRU() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("additionalComponentRUTextarea");
      });
    },
    additionalComponentEN() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("additionalComponentENTextarea");
      });
    },
  },
  computed: {
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },

    ...mapGetters({
      user: "user",
    }),
  },
};
</script>

<style lang="scss">
@media (min-width: 1264px) {
  .container {
    //1185px дефолтное название если что то ломаться начнется на маленьких мониторах
    max-width: 100% !important;
  }
}

@media (min-width: 960px) {
  //900 дефолтное название если что то ломаться начнется на маленьких мониторах
  .container {
    max-width: 100% !important;
  }
}
</style>
